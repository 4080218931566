import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "../../services/auth.service";
import { environment } from "../../../environments/environment";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/internal/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/auth.service";
export class DashboardService {
    constructor(http, authService) {
        this.http = http;
        this.authService = authService;
        this.lastAction = '';
        this.url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
    }
    getBooksData() {
        this.lastAction = 'getBooksData';
        return this.http.get(this.url + '/dashboard/getbooksdata').pipe(map((response) => response), catchError(this.errorHandler));
    }
    errorHandler(error) {
        return throwError(error.error || { message: 'Server Error' });
    }
    getHeatmap(date) {
        return this.http.post(this.url + '/dashboard/heatmap', { date: date.toString() });
    }
    getCompareChart(id) {
        return this.http.post(this.url + '/dashboard/comparechart', { book_id: id });
    }
    getPopularBooks(limit) {
        return this.http.post(this.url + '/dashboard/popularbooks', { limit: limit });
    }
    getPopularContents(id) {
        return this.http.post(this.url + '/dashboard/popularcontents', { book_id: id });
    }
}
DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: DashboardService, providedIn: "root" });
