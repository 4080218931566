import {Component, Input, OnInit, AfterViewInit, AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FormControl, FormGroup, FormArray, FormBuilder, ControlValueAccessor} from '@angular/forms';
import {BookarfilesService} from '../../../services/bookarfiles.service';
import {map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {throwError} from "rxjs";

@Component({
    selector: 'app-formfileuploadbookar',
    templateUrl: './formfileuploadbookar.component.html',
    styleUrls: ['./formfileuploadbookar.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormfileuploadbookarComponent implements OnInit {


    @Input() parentFormGroup: FormGroup;
    @Input() formType: string;
    @Input() formArrayName: string;
    @Input() removedArrayName: string;
    @Input() title: string;
    @Input() maxFileCount: number;
    @Input() sts;
    @Input() permissions;
    @Input() actionInProgress;
    @Input() readonly;
    @Input() metaKeywordsTooltip: any;

    currentFileCount: number;
    addArBtnText: string;
    addVideoBtnText: string;
    deleteBtnText: string;
    deleteMessage: string;

    constructor(private fb: FormBuilder, private cdref: ChangeDetectorRef, private bookarfilesService: BookarfilesService, private http: HttpClient) {
        // ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        // Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false'.
        cdref.detach();
    }

    ngOnInit() {
        // ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
        // Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false'.
        this.cdref.detectChanges();
        this.cdref.reattach();
        this.setCurrentFileCount();

        this.addArBtnText = this.sts.c('books.ar_add');
        this.addVideoBtnText = this.sts.c('books.video_add');
        this.deleteBtnText = this.sts.c('books.ar_delete');
        this.deleteMessage = this.sts.c('books.ar_delete_confirm_message');
    }


    addArRow(type) {
        if (this.currentFileCount == this.maxFileCount) return;
        // our formarray with coordinators
        // console.log('addArRow');
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        // we call function to push a new formgroup to the array
        if (type === 'ar') {
            control.push(this.bookarfilesService.initBookAr());
        }
        if (type === 'video') {
            control.push(this.bookarfilesService.initBookVideo());
        }

        this.setCurrentFileCount();
    }

    removeArRow(i) {
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        // console.log('removeArRow:', control);
        if (this.controlFieldsIsTouched(control.controls[i])) {
            if (confirm(this.deleteMessage)) {
                this.removeArRowAfterConfirm(control, i);
            }
        } else {
            this.removeArRowAfterConfirm(control, i);
        }

    }
    removeLocalisation(control_uid) {
      if (confirm("Biztos törlöd a lokalizációs csomagot?")) {
        this.removeLocalisationAfterConfirm(control_uid);
      }
    }

    removeLocalisationAfterConfirm(control_uid) {
      const url = `${environment.backendApiUrl}${environment.backendApiVersion}`;
      this.http.get(url + '/books/removeLocalisation/'+control_uid).subscribe((response:any) => {
        if(response.success) {
          alert(response.message);
        }
        console.log("removeLocalisationAfterConfirm BE:",response);
      })
    }


    controlFieldsIsTouched(control) {
        if (
            control.controls.title.value && control.controls.title.value.length != 0 ||
            control.controls.keywords.value && control.controls.keywords.value.length != 0 ||

            (
                (control.controls.type.value === 'ar' &&
                    (
                        control.controls.arTargetImage.value.fileCount != 0 ||
                        control.controls.arAndroid.value.fileCount != 0 ||
                        control.controls.arIos.value.fileCount != 0 ||
                        control.controls.arWebgl.value.fileCount != 0
                    )
                )
                ||
                (control.controls.type.value === 'video' &&
                    (
                        control.controls.videoTargetImage.value.fileCount != 0 ||
                        control.controls.video.value.fileCount != 0
                    )
                )
            )
        ) {
            return true;
        } else {
            return false;
        }
    }


    removeArRowAfterConfirm(control, i) {
        var block = control.controls[i].controls;
        var hasKeptFile = false;

        for (const key in block) {
            if (['arAndroid', 'arIos', 'arTargetImage', 'arWebgl', 'video', 'videoTargetImage'].indexOf(key) < 0) continue;

            if (block[key].value.keptFiles.length > 0) {
                block[key].value.removedFiles = block[key].value.keptFiles;
                block[key].value.keptFiles = [];
                block[key].value.fileCount = 0;
                hasKeptFile = true;
            }
        }

        if (hasKeptFile) {
            const removedControl = <FormArray>this.parentFormGroup.controls[this.removedArrayName];
            removedControl.push(control.controls[i]);
        }

        control.removeAt(i);
        this.setCurrentFileCount();
    }

    setCurrentFileCount() {
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        this.currentFileCount = control.controls.length;
    }

    destroy(element) {
        if (this.formType == 'copy') {
            this.setNewUidIfKeeptIsEmpty(element);
        }
    }

    setNewUidIfKeeptIsEmpty(i) {
        const control = <FormArray>this.parentFormGroup.controls[this.formArrayName];
        const block = control.controls[i]['controls'];

        // copy: If remove all stored files, and add new, the block keep the old uid and it's wrong and need to change.
        if (
            block['arAndroid'].value.keptFiles.length == 0 &&
            block['arIos'].value.keptFiles.length == 0 &&
            block['arTargetImage'].value.keptFiles.length == 0 &&
            block['arWebgl'].value.keptFiles.length == 0 &&
            block['videoTargetImage'].value.keptFiles.length == 0 &&
            block['video'].value.keptFiles.length == 0
        ) {
            block['uid'].value = this.bookarfilesService.generateUid();
        }
    }

}
