import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from "@angular/core";
import { PermissionsService } from "../../services/permissions.service";
import { environment } from "../../../../environments/environment";
import { DashboardService } from "../../services/dashboard.service";
import { MessageService } from "../../services/message.service";
import { Hybridbooks, Royal } from "./themes/themes";
import * as moment from "moment";
import { AdmingridService } from "../../services/admingrid.service";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
export class DashboardComponent {
    constructor(permissionsService, dashboardService, messageService, admingridService) {
        this.permissionsService = permissionsService;
        this.dashboardService = dashboardService;
        this.messageService = messageService;
        this.admingridService = admingridService;
        this.readyToShow = false;
        this.rootUrl = `${environment.backendApiUrl}${environment.backendApiVersion}`;
        this.isSuperadmin = JSON.parse(sessionStorage.getItem("userroles")).indexOf("Superadmin") != -1;
        this.subsink = new SubSink();
        this.bookChartData = [];
        this.theme = Hybridbooks;
        this.theme2 = Royal;
        this.dateData = this.CreateDateData(2022, 2030);
        this.dateSettings = {
            singleSelection: true,
            text: "Dátum kiválasztása",
            enableSearchFilter: false,
            enableCheckAll: false,
            badgeShowLimit: 5,
            labelKey: "year",
            enableFilterSelectAll: false,
        };
        this.dropdownSettings = {
            singleSelection: false,
            text: "Könyvek kiválasztása",
            enableSearchFilter: true,
            enableCheckAll: false,
            badgeShowLimit: 5,
            labelKey: "name",
            enableFilterSelectAll: false,
        };
        this.fileViewSettings = {
            singleSelection: true,
            text: "Könyv kiválasztása",
            enableSearchFilter: true,
            enableCheckAll: false,
            badgeShowLimit: 5,
            labelKey: "name",
            enableFilterSelectAll: false,
        };
        this.popularBooksSettings = {
            singleSelection: true,
            text: "Népszerűség kiválasztása",
            enableSearchFilter: false,
            enableCheckAll: false,
            badgeShowLimit: 5,
            labelKey: "name",
            enableFilterSelectAll: false,
        };
        this.popularLimitData = [{ id: 0, name: 'Top 5', value: 5 }, { id: 1, name: 'Top 10', value: 10 }, { id: 2, name: 'Top 20', value: 20 }];
    }
    CreateDateData(from, to) {
        let dateData = [];
        let i = 0;
        for (let year = from; year <= to; year++) {
            const element = { id: i, year: year };
            dateData.push(element);
            i++;
        }
        return dateData;
    }
    OnDateSelect(ev) {
        this.GetHeatmap(ev);
    }
    GetHeatmap(date) {
        this.subsink.add(this.dashboardService.getHeatmap(date.year).subscribe((resp) => {
            var heatmapdata = this.GetHeatmapData(date.year, resp.data);
            this.CreateCalendarChart(date.year, heatmapdata.data, heatmapdata.max);
        }));
    }
    GetHeatmapData(year, appUsageData) {
        let date = moment(year + "-01-01").valueOf();
        let end = moment(year + 1 + "-01-01").valueOf();
        var dayTime = 3600 * 24 * 1000;
        var data = [];
        for (var time = date; time < end; time += dayTime) {
            let currentDate = moment(time).format("yyyy-MM-DD");
            let usageData = appUsageData.filter((x) => x[0] == currentDate);
            if (usageData.length == 0) {
                data.push([currentDate, 0]);
            }
            else {
                data.push(usageData[0]);
            }
        }
        let viewCountArray = appUsageData.map((x) => x[1]);
        viewCountArray = [...new Set(viewCountArray)];
        let maxVal = 0;
        viewCountArray.forEach((x) => {
            if (x > maxVal) {
                maxVal = x;
            }
        });
        return { data: data, max: maxVal };
    }
    CreateCalendarChart(year, data, max) {
        this.showHeatmapData = false;
        var symbolSize = max / 20;
        let calendarChart = {
            tooltip: {
                formatter: (params) => {
                    return this.TooltipFormatter(params);
                },
            },
            visualMap: {
                min: 1,
                max: max,
                type: "piecewise",
                orient: "horizontal",
                left: "center",
                splitNumber: 5,
                top: 65,
                inRange: {
                    color: ["#be9800", "#009000"],
                },
            },
            calendar: {
                top: 120,
                left: 30,
                right: 30,
                cellSize: ["auto", 20],
                range: year,
                dayLabel: {
                    nameMap: ["V", "H", "K", "SZ", "CS", "P", "SZ"],
                },
                yearLabel: {
                    show: true,
                    color: "#fff",
                },
                itemStyle: {
                    color: "#e7e7e7",
                    borderWidth: 0.5,
                    borderColor: "#2A3F54",
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#2A3F54",
                        width: 2,
                        type: "solid",
                    },
                },
            },
            series: {
                name: "App használat",
                type: "scatter",
                coordinateSystem: "calendar",
                data: data,
                symbolSize: function (val) {
                    return val[1] / symbolSize;
                },
            },
        };
        this.calendarHeatmapOptions = calendarChart;
        this.showHeatmapData = true;
    }
    ngOnInit() {
        this.tableName = "dashboard";
        this.permissions = this.permissionsService.getPermissions(this.tableName);
        this.GetPublications();
        //TODO SAVE TO LOCAL STORAGE
        this.selectedDate = [this.dateData[0]];
        this.GetHeatmap(this.dateData[0]);
        this.dashboardService.getBooksData().subscribe((data) => {
            this.st = data.st;
            this.readyToShow = true;
        }, (error) => {
            console.log(error);
            var text = typeof error["error"] == "object" &&
                error.error.hasOwnProperty("message")
                ? error.error.message
                : error.message;
            this.messageService.sendMessage({ text: text, type: "danger" });
        });
    }
    GetPopular() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const books = yield this.GetPopularBooks(2);
            const contents = yield this.GetPopularContents(2);
            console.log(books);
            console.log(contents);
        });
    }
    ngOnDestroy() {
        this.subsink.unsubscribe();
    }
    GetCompareChart(bookId) {
        return this.dashboardService.getCompareChart(bookId).toPromise();
    }
    GetPublications() {
        this.subsink.add(this.admingridService
            .getItems("books", 0, 0, [], "", {})
            .pipe(map((books) => books.items))
            .subscribe((b) => {
            this.booksInfo = b;
            let bookData = [];
            b.forEach((book) => {
                var bookChart = {
                    id: book.id,
                    name: book.name,
                };
                bookData.push(bookChart);
            });
            this.bookChartData = bookData;
            this.LoadSelectedBooks();
            this.popularLimit = [{ id: 0, name: 'Top 5', value: 5 }];
            this.CreatePopularBooksChart(5);
        }));
    }
    GetAllBooks(books) {
        var books_1, books_1_1;
        var e_1, _a;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const promiseArray = new Array(books.length);
            let i = 0;
            try {
                for (books_1 = tslib_1.__asyncValues(books); books_1_1 = yield books_1.next(), !books_1_1.done;) {
                    const book = books_1_1.value;
                    const a = this.GetBook(book.id);
                    promiseArray[i] = a;
                    i++;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (books_1_1 && !books_1_1.done && (_a = books_1.return)) yield _a.call(books_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            const files = yield Promise.all(promiseArray);
            return files;
        });
    }
    GetBook(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.admingridService.getItem("books", id).pipe(map((data) => data.item)).toPromise();
        });
    }
    GetPopularBooks(limit) {
        return this.dashboardService.getPopularBooks(limit).toPromise();
    }
    GetPopularContents(id) {
        return this.dashboardService.getPopularContents(id).toPromise();
    }
    CreateFileViewChart(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let titles = [];
            let values = [];
            const book = yield this.GetBook(id);
            const arFiles = book.ar_files_grouped;
            const contents = yield this.GetPopularContents(id);
            contents.data.forEach(content => {
                const file = arFiles.find(ar => ar.uid == content.fc_uid);
                let title = file.title;
                titles.push(title);
                values.push(content.count);
            });
            titles.reverse();
            values.reverse();
            this.fileViewOptions = {
                title: {
                    text: "Legnézettebb tartalmak",
                    textStyle: {
                        fontWeight: "lighter",
                    },
                    padding: [12, 8],
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "value",
                    boundaryGap: [0, 0.01],
                },
                yAxis: {
                    type: "category",
                    data: titles,
                },
                series: [
                    {
                        type: "bar",
                        data: values,
                    },
                ],
            };
        });
    }
    CreatePopularBooksChart(limit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let popBooks = yield this.GetPopularBooks(limit);
            let popularBooks = popBooks.data;
            let names = [];
            let values = [];
            for (let i = popularBooks.length - 1; i >= 0; i--) {
                const book = popularBooks[i];
                const id = parseInt(book[0]);
                let b = this.booksInfo.find(x => x.id == id);
                const value = book[1];
                names.push(b.name);
                values.push(value);
            }
            this.popularBooksOptions = {
                title: {
                    text: "Legnépszerűbb kiadványok",
                    textStyle: {
                        fontWeight: "lighter",
                    },
                    padding: [12, 8],
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "value",
                    boundaryGap: [0, 0.01],
                },
                yAxis: {
                    type: "category",
                    data: names,
                },
                series: [
                    {
                        type: "bar",
                        data: values,
                    },
                ],
            };
        });
    }
    CreatePieChart() {
        let pieChartData = [];
        this.selectedBooks.forEach((bookChart) => {
            let pieces = bookChart.data.bookRegistration.map((u) => u[1]);
            let total = 0;
            pieces.map((v) => (total += v));
            pieChartData.push({
                id: bookChart.id,
                name: bookChart.name,
                value: total,
            });
        });
        this.pieChartOptions = {
            title: {
                text: "Összes aktiválás",
                textStyle: {
                    fontWeight: "lighter",
                },
                padding: [12, 8],
            },
            tooltip: {
                trigger: "item",
            },
            series: [
                {
                    name: "Összes aktiválás",
                    type: "pie",
                    radius: ["40%", "70%"],
                    avoidLabelOverlap: false,
                    labelLine: {
                        length: 30,
                    },
                    label: {
                        formatter: "{b|{b}}\n{hr|}\n{c|{c}} {per|{d}%}",
                        backgroundColor: "#F6F8FC",
                        borderColor: "#8C8D8E",
                        padding: [0, 8, 0, 8],
                        borderWidth: 1,
                        borderRadius: 4,
                        rich: {
                            c: {
                                color: "#4C5058",
                                fontSize: 12,
                                align: "left",
                                fontWeight: "bold",
                                lineHeight: 24,
                            },
                            hr: {
                                borderColor: "#8C8D8E",
                                width: "100%",
                                borderWidth: 1,
                                height: 0,
                            },
                            b: {
                                color: "#4C5058",
                                fontSize: 14,
                                lineHeight: 26,
                            },
                            per: {
                                color: "#fff",
                                backgroundColor: "#4C5058",
                                align: "right",
                                padding: [3, 4],
                                borderRadius: 4,
                            },
                        },
                    },
                    data: pieChartData,
                },
            ],
        };
    }
    CreateViewsChart() {
        let legendData = [];
        let series = [];
        this.selectedBooks.forEach((bookChart) => {
            legendData.push(bookChart.name);
            let data = [];
            bookChart.data.views.forEach(view => {
                data.push(view);
            });
            series.push({
                data: data,
                name: bookChart.name,
                type: "bar",
                barMaxWidth: 10,
                barMinWidth: 4,
                animationDelay: (idx) => idx * 10,
            });
        });
        let chart = {
            title: {
                text: "Könyv használat",
                textStyle: {
                    fontWeight: "lighter",
                },
                padding: [12, 8],
            },
            legend: {
                data: legendData,
                orient: "horizontal",
                align: "left",
            },
            xAxis: {
                type: "time",
                axisLabel: {
                    formatter: "{yyyy}-{MM}-{dd}",
                },
            },
            yAxis: {
                type: "value",
            },
            dataZoom: [
                {
                    type: "slider",
                    start: 80,
                    end: 100,
                },
            ],
            tooltip: {
                formatter: (params) => {
                    return this.TooltipFormatter(params);
                },
            },
            series: [],
            animationEasing: "elasticOut",
            animationDelayUpdate: (idx) => idx * 5,
        };
        chart.series = series;
        this.viewsChartOptions = chart;
    }
    CreateCompareChart() {
        let dates = [];
        let legendData = [];
        let series = [];
        this.selectedBooks.forEach((bookChart) => {
            dates = bookChart.data.bookRegistration.map((u) => u[0]);
            let pieces = bookChart.data.bookRegistration.map((u) => u[1]);
            legendData.push(bookChart.name);
            let data = [];
            for (let i = 0; i < dates.length; i++) {
                const date = dates[i];
                data.push([date, pieces[i]]);
            }
            series.push({
                data: data,
                name: bookChart.name,
                type: "bar",
                barMaxWidth: 10,
                barMinWidth: 4,
                animationDelay: (idx) => idx * 10,
            });
        });
        let chart = {
            title: {
                text: "Könyvregisztráció",
                textStyle: {
                    fontWeight: "lighter",
                },
                padding: [12, 8],
            },
            legend: {
                data: legendData,
                orient: "horizontal",
                align: "left",
            },
            xAxis: {
                type: "time",
            },
            yAxis: {
                type: "value",
            },
            dataZoom: [
                {
                    type: "slider",
                    start: 80,
                    end: 100,
                },
            ],
            tooltip: {
                formatter: (params) => {
                    return this.TooltipFormatter(params);
                },
            },
            series: [],
            animationEasing: "elasticOut",
            animationDelayUpdate: (idx) => idx * 5,
        };
        chart.series = series;
        this.compareChartOptions = chart;
    }
    TooltipFormatter(params) {
        return (params.marker +
            params.seriesName +
            '<br><div style="font-size:16px;font-weight:bold">' +
            params.value[1] +
            "</div>" +
            params.value[0]);
    }
    OnBookSelect($event) {
        this.CreateFileViewChart($event.id);
    }
    OnBookDeSelect($event) {
    }
    OnPopularSelect($event) {
        console.log($event);
        this.CreatePopularBooksChart($event.value);
    }
    OnPopularDeSelect($event) {
    }
    //Multi Select functions
    OnItemSelect($event) {
        this.UpdateCharts();
    }
    OnItemSelectAll($event) {
        this.UpdateCharts();
    }
    OnItemDeSelect($event) {
        this.UpdateCharts();
    }
    OnDeSelectAll($event) {
        this.selectedBooks = [];
        this.UpdateCharts();
    }
    UpdateCharts() {
        var e_2, _a;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let selectedIds = this.selectedBooks.map((i) => i.id);
            this.selectedBooks = this.bookChartData.filter((d) => selectedIds.includes(d.id));
            try {
                for (var _b = tslib_1.__asyncValues(this.selectedBooks), _c; _c = yield _b.next(), !_c.done;) {
                    const item = _c.value;
                    let response = yield this.GetCompareChart(item.id);
                    item.data = response.data;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) yield _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            this.CreateCompareChart();
            this.CreatePieChart();
            this.CreateViewsChart();
            this.SaveSelectedBooks();
        });
    }
    LoadSelectedBooks() {
        var e_3, _a;
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let idsString = localStorage.getItem("dashboard_items");
            if (idsString == null) {
                return;
            }
            let ids = idsString.split(",").map((id) => parseInt(id));
            let books = [];
            try {
                for (var _b = tslib_1.__asyncValues(this.bookChartData), _c; _c = yield _b.next(), !_c.done;) {
                    const book = _c.value;
                    if (ids.includes(book.id)) {
                        books.push(book);
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) yield _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
            this.selectedBooks = books;
            this.UpdateCharts();
        });
    }
    SaveSelectedBooks() {
        let ids = this.selectedBooks.map((i) => i.id);
        localStorage.setItem("dashboard_items", ids.toString());
    }
}
