import { OnInit } from '@angular/core';
import { AdmingridComponent } from '../../controllers/admingrid/admingrid.component';
import { DropdownFilter } from '../../controllers/admingrid/dropdownfilter.component';
export class ClientStatisticsComponent extends AdmingridComponent {
    constructor() {
        super(...arguments);
        this.colDef_id = { headerName: 'Id', field: 'id', colId: 'cs.id', sortable: true, resizable: true, width: 80, alwaysShow: true, show: true, filter: 'agNumberColumnFilter' };
        this.colDef_device_uid = { headerName: '', field: 'device_uid', colId: 'cs.device_uid', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_org_id = { headerName: '', field: 'org_id', colId: 'cs.org_id', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter' };
        this.colDef_event_type = { headerName: '', field: 'event_type', colId: 'cs.event_type', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_event_name = { headerName: '', field: 'event_name', colId: 'cs.event_name', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_platform = { headerName: '', field: 'platform', colId: 'cs.platform', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_bid = { width: 150, headerName: '', field: 'bid', colId: 'cs.bid', sortable: true, resizable: true, show: true, filter: 'agTextColumnFilter' };
        this.colDef_buid = { headerName: '', field: 'buid', colId: 'cs.buid', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter' };
        this.colDef_fc_name = { headerName: '', field: 'fc_name', colId: 'cs.fc_name', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter' };
        this.colDef_fc_uid = { headerName: '', field: 'fc_uid', colId: 'cs.fc_uid', sortable: true, resizable: true, show: false, filter: 'agTextColumnFilter' };
        this.colDef_date = { width: 240, headerName: '', field: 'date', colId: 'cs.date', sortable: true, resizable: true, show: true, filter: 'agDateColumnFilter' };
        this.colDef_start_date = { width: 240, headerName: '', field: 'start_date', colId: 'cs.start_date', sortable: true, resizable: true, show: true, filter: 'agDateColumnFilter' };
        this.colDef_end_date = { width: 240, headerName: '', field: 'end_date', colId: 'cs.end_date', sortable: true, resizable: true, show: true, filter: 'agDateColumnFilter' };
        this.colDef_cancelled = { headerName: '', field: 'cancelled', colId: 'cs.cancelled', sortable: true, resizable: true, show: true, filter: 'agNumberColumnFilter' };
        this.columnDefs = [
            this.colDef_id,
            this.colDef_device_uid,
            this.colDef_org_id,
            this.colDef_event_type,
            this.colDef_event_name,
            this.colDef_platform,
            this.colDef_bid,
            this.colDef_buid,
            this.colDef_fc_name,
            this.colDef_fc_uid,
            this.colDef_date,
            this.colDef_start_date,
            this.colDef_end_date,
            this.colDef_cancelled
        ];
        this.firstLoadDone = false;
    }
    ngOnInit() {
        super.ngOnInit();
        this.frameworkComponents = { DropdownFilter: DropdownFilter };
        this.tableName = 'clientstatistics';
        this.formName = 'Clientstatistics';
        this.setPermissions('systemevents');
        this.setColumnDefs(this.columnDefs);
        this.firstLoadDone = false;
        this.actionBtnsDisplay.Create = false;
        this.actionBtnsDisplay.Show = false;
        this.actionBtnsDisplay.Edit = false;
        this.actionBtnsDisplay.Delete = false;
        this.messageService.getStatus().subscribe(message => {
            if (message) {
                if (message.name == 'grid' && message.status == 'ready' && message.form == this.formName && this.firstLoadDone === false) {
                    this.setColNameByLang(this.colDef_device_uid, 'clientstatistics.device_uid', message.gridOptions);
                    this.setColNameByLang(this.colDef_org_id, 'clientstatistics.org_id', message.gridOptions);
                    this.setColNameByLang(this.colDef_bid, 'clientstatistics.bid', message.gridOptions);
                    this.setColNameByLang(this.colDef_buid, 'clientstatistics.buid', message.gridOptions);
                    this.setColNameByLang(this.colDef_platform, 'clientstatistics.platform', message.gridOptions);
                    this.setColNameByLang(this.colDef_event_type, 'clientstatistics.event_type', message.gridOptions);
                    this.setColNameByLang(this.colDef_event_name, 'clientstatistics.event_name', message.gridOptions);
                    this.setColNameByLang(this.colDef_fc_name, 'clientstatistics.fc_name', message.gridOptions);
                    this.setColNameByLang(this.colDef_fc_uid, 'clientstatistics.fc_uid', message.gridOptions);
                    this.setColNameByLang(this.colDef_date, 'clientstatistics.date', message.gridOptions);
                    this.setColNameByLang(this.colDef_start_date, 'clientstatistics.start_date', message.gridOptions);
                    this.setColNameByLang(this.colDef_end_date, 'clientstatistics.end_date', message.gridOptions);
                    this.setColNameByLang(this.colDef_cancelled, 'clientstatistics.cancelled', message.gridOptions);
                    message.gridOptions.api.refreshHeader();
                    this.onColumnsChanged();
                    this.firstLoadDone = true;
                }
            }
        });
    }
}
