import { OnDestroy, OnInit } from '@angular/core';
import { AdminformComponent } from "../../controllers/adminform/adminform.component";
import { FormControl } from "@angular/forms";
export class SystemmessageComponent extends AdminformComponent {
    constructor() {
        super(...arguments);
        this.id = 1;
    }
    ngOnInit() {
        this.tableName = 'systemmessage';
        this.formName = 'systemmessage';
        this.setPermissions('books');
        this.sts.st = { form: this.localeText };
        this.customRedirect = '/admin/' + this.tableName + '/edit';
        this.permissions = this.permissionsService.getPermissions('books');
        this.params = this.admingridService.getItem(this.tableName, "0").subscribe(data => {
            this.sts.st = Object.assign({}, this.sts.st, data.st);
            this.replaceSystemTitle('systemmessage.systemmessage');
            this.mainForm.controls['hutext'].setValue(data['item']['hutext']);
            this.mainForm.controls['entext'].setValue(data['item']['entext']);
            this.mainForm.controls['detext'].setValue(data['item']['detext']);
            this.mainForm.controls['pltext'].setValue(data['item']['pltext']);
            this.mainForm.controls['active'].setValue(data['item']['active']);
            this.activatedRoute.queryParams.subscribe(params => {
                let messageType = params['messageType'];
                if (messageType == "success") {
                    alert(this.sts.st.systemmessage.successText);
                }
                else if (messageType == "error") {
                    alert(this.sts.st.systemmessage.errorText);
                }
                this.router.navigate([], {
                    queryParams: {
                        'messageType': null,
                    },
                    queryParamsHandling: 'merge'
                });
            });
            this.formReadyToShow = true;
        }, error => {
            console.log(error);
            var text = typeof (error['error']) == 'object' && error.error.hasOwnProperty('message') ? error.error.message : error.message;
            this.messageService.sendMessage({ text: text, type: 'danger' });
        });
        this.huTextControl = this.fb.control('');
        this.enTextControl = this.fb.control('');
        this.deTextControl = this.fb.control('');
        this.plTextControl = this.fb.control('');
        this.activeControl = this.fb.control('');
        this.mainForm = this.fb.group({
            id: '1',
            hutext: this.huTextControl,
            entext: this.enTextControl,
            detext: this.deTextControl,
            pltext: this.plTextControl,
            active: this.activeControl,
        });
    }
    ngOnDestroy() {
        this.params.unsubscribe();
    }
}
